import React from 'react'
import styled, { keyframes } from 'styled-components'
import { SpinnerProps } from './types'
import { BlastLoader } from '../Svg'

const spin = keyframes`
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4B973F, 1.8em -1.8em 0 0em #4B973F, 2.5em 0em 0 0em #4B973F, 1.75em 1.75em 0 0em #4B973F, 0em 2.5em 0 0em #4B973F, -1.8em 1.8em 0 0em #4B973F, -2.6em 0em 0 0em rgba(255,255,255, 0.5), -1.8em -1.8em 0 0em rgba(255,255,255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.7), 1.8em -1.8em 0 0em #4B973F, 2.5em 0em 0 0em #4B973F, 1.75em 1.75em 0 0em #4B973F, 0em 2.5em 0 0em #4B973F, -1.8em 1.8em 0 0em #4B973F, -2.6em 0em 0 0em #4B973F, -1.8em -1.8em 0 0em rgba(255,255,255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.5), 1.8em -1.8em 0 0em rgba(255,255,255, 0.7), 2.5em 0em 0 0em #4B973F, 1.75em 1.75em 0 0em #4B973F, 0em 2.5em 0 0em #4B973F, -1.8em 1.8em 0 0em #4B973F, -2.6em 0em 0 0em #4B973F, -1.8em -1.8em 0 0em #4B973F;
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em #4B973F, 1.8em -1.8em 0 0em rgba(255,255,255, 0.5), 2.5em 0em 0 0em rgba(255,255,255, 0.7), 1.75em 1.75em 0 0em #4B973F, 0em 2.5em 0 0em #4B973F, -1.8em 1.8em 0 0em #4B973F, -2.6em 0em 0 0em #4B973F, -1.8em -1.8em 0 0em #4B973F;
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em #4B973F, 1.8em -1.8em 0 0em #4B973F, 2.5em 0em 0 0em rgba(255,255,255, 0.5), 1.75em 1.75em 0 0em rgba(255,255,255, 0.7), 0em 2.5em 0 0em #4B973F, -1.8em 1.8em 0 0em #4B973F, -2.6em 0em 0 0em #4B973F, -1.8em -1.8em 0 0em #4B973F;
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em #4B973F, 1.8em -1.8em 0 0em #4B973F, 2.5em 0em 0 0em #4B973F, 1.75em 1.75em 0 0em rgba(255,255,255, 0.5), 0em 2.5em 0 0em rgba(255,255,255, 0.7), -1.8em 1.8em 0 0em #4B973F, -2.6em 0em 0 0em #4B973F, -1.8em -1.8em 0 0em #4B973F;
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em #4B973F, 1.8em -1.8em 0 0em #4B973F, 2.5em 0em 0 0em #4B973F, 1.75em 1.75em 0 0em #4B973F, 0em 2.5em 0 0em rgba(255,255,255, 0.5), -1.8em 1.8em 0 0em rgba(255,255,255, 0.7), -2.6em 0em 0 0em #4B973F, -1.8em -1.8em 0 0em #4B973F;
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em #4B973F, 1.8em -1.8em 0 0em #4B973F, 2.5em 0em 0 0em #4B973F, 1.75em 1.75em 0 0em #4B973F, 0em 2.5em 0 0em #4B973F, -1.8em 1.8em 0 0em rgba(255,255,255, 0.5), -2.6em 0em 0 0em rgba(255,255,255, 0.7), -1.8em -1.8em 0 0em #4B973F;
  }
`

const Basic = styled.div`
  &::before {
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    animation: ${spin} 1s infinite linear;
    font-size: 26px;
    border-radius: 50%;
  }
`

const Container = styled.div``

const Spinner: React.FC<SpinnerProps> = () => {
  return (
    <Container>
      <Basic />
    </Container>
  )
}

export default Spinner
