import { useCallback } from 'react'
import { ChainId, Currency, Token } from '@starex-dex/sdk'
import styled from 'styled-components'
import {
  Button,
  Text,
  ErrorIcon,
  // ArrowUpIcon,
  // MetamaskIcon,
  Flex,
  Box,
  Link,
  Spinner,
  Modal,
  InjectedModalProps,
  ConfirmChecked,
} from 'packages/uikit'
import { registerToken } from 'utils/wallet'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { WrappedTokenInfo } from 'state/types'
import { RowFixed } from '../Layout/Row'
import { AutoColumn, ColumnCenter } from '../Layout/Column'
import { getBscScanLink } from '../../utils'

const Wrapper = styled.div`
  width: 100%;
  padding: 12px 0 16px;
`
const Section = styled(AutoColumn)`
  padding: 0 26px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  width: auto;
  margin: auto;
  border-radius: 100%;
  margin: 24px 0;
`

const StyledFlex = styled(Flex)`
  justify-content: center;
  padding: 125px 0;
`

const StyledText = styled(Text).attrs({ fontSize: ['12px', , , , '16px'], textAlign: 'center' })`
  white-space: nowrap;
`

const StyledModal = styled(Modal)`
  /* max-width: 705px; */

  ${({ theme }) => theme.mediaQueries.xxs} {
    min-width: 240px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    min-width: 320px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    min-width: 400px;
  }
`

function ConfirmationPendingContent({ pendingText, onDismiss }: { pendingText: string; onDismiss: () => void }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <StyledFlex>
        <Spinner />
      </StyledFlex>
      <AutoColumn gap="12px" justify="center">
        <StyledText color="black">{t('Waiting For Confirmation')}</StyledText>
        <AutoColumn gap="12px" justify="center">
          <Text color="black" fontSize={['10px', , , , '14px']} textAlign="center">
            {pendingText}
          </Text>
        </AutoColumn>
        <Text color="black" fontSize={['10px', , , , '14px']} textAlign="center">
          {t('Confirm this transaction in your wallet')}
        </Text>
      </AutoColumn>
      <Box padding={['0 20px 16px']}>
        <Button width="100%" onClick={onDismiss} mt="32px">
          <Text color="black" fontSize={['12px', , , , '16px']}>
            {t('Close')}
          </Text>
        </Button>
      </Box>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const { library } = useActiveWeb3React()

  const { t } = useTranslation()

  const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)

  return (
    <Wrapper>
      <Section style={{ gap: '20px' }}>
        <ConfirmedIcon>
          <ConfirmChecked strokeWidth={0.5} width="145px" color="primary" />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          <StyledText color="black" bold mb="16px">
            {t('Successful Transaction')}
          </StyledText>
          {chainId && hash && (
            <Link
              color="black"
              external
              fontSize={['10px', , , , '14px']}
              href={getBscScanLink(hash, 'transaction', chainId)}
            >
              {t('View on Explorer')}
            </Link>
          )}
          {/* {currencyToAdd && library?.provider?.isMetaMask && (
            <Button
              mt="10px"
              width="fit-content"
              onClick={() =>
                registerToken(
                  token.address,
                  token.symbol,
                  token.decimals,
                  token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                )
              }
            >
              <RowFixed>
                {t('Add %asset% to Metamask', { asset: currencyToAdd.symbol })}
                <MetamaskIcon width="24px" ml="6px" />
              </RowFixed>
            </Button>
          )} */}
          <Button width="100%" onClick={onDismiss} mt="16px" mb="16px">
            {t('Close')}
          </Button>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AutoColumn justify="center">
        <ErrorIcon color="black" width="64px" />
        <Text color="black" textAlign="center" fontSize={['12px', , , , '16px']}>
          {message}
        </Text>
      </AutoColumn>

      <Flex justifyContent="center" pt="24px" width="380px">
        <Button onClick={onDismiss} width="100%">
          <Text color="black" textAlign="center" fontSize={['12px', , , , '16px']}>
            {t('Dismiss')}
          </Text>
        </Button>
      </Flex>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
  isErrorMessage?: string | undefined
}

const TransactionConfirmationModal: React.FC<InjectedModalProps & ConfirmationModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
  isErrorMessage,
}) => {
  const { chainId } = useActiveWeb3React()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <StyledModal
      title={title}
      headerBackground="gradients.cardHeader"
      onDismiss={handleDismiss}
      maxWidth={isErrorMessage ? '425px' : '705px'}
    >
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} onDismiss={handleDismiss} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </StyledModal>
  )
}

export default TransactionConfirmationModal
