import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 181 182">
      <path
        d="M165.305 22.744L154.213 55.4912C153.83 56.2486 153.631 57.0854 153.631 57.9341C153.631 58.7827 153.83 59.6196 154.213 60.3769L174.944 88.1065C175.54 88.9002 175.9 89.8456 175.984 90.8345C176.067 91.8235 175.87 92.8159 175.415 93.6979C174.96 94.58 174.266 95.316 173.413 95.8217C172.558 96.3275 171.58 96.5825 170.587 96.5574H135.991C135.143 96.5823 134.311 96.7969 133.557 97.1854C132.803 97.5739 132.146 98.1263 131.634 98.8021L112.355 126.532C111.79 127.342 111.008 127.978 110.1 128.366C109.191 128.755 108.191 128.881 107.215 128.729C106.238 128.578 105.323 128.156 104.575 127.511C103.826 126.865 103.274 126.023 102.98 125.079L92.6804 92.0678C92.4455 91.2519 92.0072 90.5089 91.4068 89.9083C90.8063 89.3079 90.0632 88.8697 89.2473 88.6346L56.236 78.3351C55.2922 78.0414 54.4499 77.4889 53.8046 76.7404C53.1593 75.9918 52.737 75.0771 52.5857 74.1005C52.4344 73.1239 52.5602 72.1243 52.9487 71.2156C53.3372 70.3069 53.9728 69.5253 54.7835 68.9599L83.0411 49.021C83.717 48.5085 84.2695 47.8511 84.658 47.097C85.0464 46.343 85.261 45.5114 85.2858 44.6635V10.0676C85.3228 9.15896 85.5936 8.27521 86.0723 7.50193C86.551 6.72865 87.2211 6.09206 88.018 5.6538C88.8149 5.21553 89.7113 4.99043 90.6207 5.00031C91.53 5.01018 92.4214 5.25467 93.2086 5.71014L120.938 26.4412C121.642 26.9302 122.452 27.245 123.301 27.3597C124.151 27.4746 125.015 27.386 125.824 27.1015L158.571 16.0096C159.51 15.6797 160.524 15.6217 161.494 15.8425C162.465 16.0634 163.353 16.5541 164.057 17.2579C164.761 17.9618 165.252 18.8503 165.473 19.8209C165.693 20.7915 165.635 21.8049 165.305 22.744Z"
        fill="#FFD524"
        stroke="#4B973F"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.3577 89.9561L5 176.314"
        stroke="#4B973F"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
